const MessageFooterIcon = () => {
    const svgIcon = `
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 10.3999C0 4.87705 4.47715 0.399902 10 0.399902H38C43.5229 0.399902 48 4.87705 48 10.3999V38.3999C48 43.9228 43.5228 48.3999 38 48.3999H10C4.47715 48.3999 0 43.9227 0 38.3999V10.3999Z" fill="#0b9444"/>
<path d="M18.0944 23.6287C18.0322 23.2281 18 22.8178 18 22.3999C18 17.9816 21.6052 14.3999 26.0526 14.3999C30.4999 14.3999 34.1052 17.9816 34.1052 22.3999C34.1052 23.398 33.9213 24.3534 33.5852 25.2344C33.5154 25.4174 33.4804 25.5089 33.4646 25.5803C33.4489 25.6511 33.4428 25.7009 33.4411 25.7734C33.4394 25.8465 33.4493 25.9271 33.4692 26.0882L33.8717 29.3584C33.9153 29.7124 33.9371 29.8894 33.8782 30.0181C33.8266 30.1309 33.735 30.2204 33.6211 30.2694C33.4911 30.3253 33.3146 30.2994 32.9617 30.2477L29.7765 29.7808C29.6101 29.7564 29.527 29.7442 29.4512 29.7447C29.3763 29.7451 29.3245 29.7506 29.2511 29.766C29.177 29.7816 29.0823 29.8171 28.893 29.888C28.0097 30.2189 27.0524 30.3999 26.0526 30.3999C25.6344 30.3999 25.2237 30.3682 24.8227 30.3072M19.6316 34.3999C22.5965 34.3999 25 31.9375 25 28.8999C25 25.8623 22.5965 23.3999 19.6316 23.3999C16.6667 23.3999 14.2632 25.8623 14.2632 28.8999C14.2632 29.5105 14.3603 30.0978 14.5395 30.6466C14.6153 30.8786 14.6532 30.9946 14.6657 31.0738C14.6786 31.1566 14.6809 31.203 14.6761 31.2866C14.6714 31.3667 14.6514 31.4572 14.6113 31.6382L14 34.3999L16.9948 33.9909C17.1583 33.9686 17.24 33.9574 17.3114 33.9579C17.3865 33.9584 17.4264 33.9625 17.5001 33.9772C17.5701 33.9911 17.6742 34.0279 17.8823 34.1013C18.4306 34.2948 19.0191 34.3999 19.6316 34.3999Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
      `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} className="mb-8"/>;
  };
  
  export default MessageFooterIcon;
  




