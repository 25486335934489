const MessageIcon = () => {
    const svgIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 14 14" fill="none">
    <g clip-path="url(#clip0_1222_2941)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.50001 0C2.10219 0 1.72065 0.158035 1.43935 0.43934C1.15805 0.720644 1.00001 1.10218 1.00001 1.5V10.4189L0.0256687 13.3419C-0.0320399 13.515 0.00915603 13.7058 0.133149 13.8397C0.257142 13.9736 0.444236 14.0293 0.621278 13.9851L4.56156 13H12.5C12.8978 13 13.2794 12.842 13.5607 12.5607C13.842 12.2794 14 11.8978 14 11.5V1.5C14 1.10217 13.842 0.720644 13.5607 0.43934C13.2794 0.158035 12.8978 0 12.5 0H2.50001ZM3.875 4.84558C3.875 4.5004 4.15482 4.22058 4.5 4.22058H10.5C10.8452 4.22058 11.125 4.5004 11.125 4.84558C11.125 5.19076 10.8452 5.47058 10.5 5.47058H4.5C4.15482 5.47058 3.875 5.19076 3.875 4.84558ZM4.5 7.52942C4.15482 7.52942 3.875 7.80924 3.875 8.15442C3.875 8.4996 4.15482 8.77942 4.5 8.77942H8.5C8.84518 8.77942 9.125 8.4996 9.125 8.15442C9.125 7.80924 8.84518 7.52942 8.5 7.52942H4.5Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_1222_2941">
        <rect width="14" height="14" fill="white"/>
      </clipPath>
    </defs>
  </svg>    
      `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default MessageIcon;
  