const PhoneIcon = () => {
    const svgIcon = `
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 10.3999C0 4.87705 4.47715 0.399902 10 0.399902H38C43.5229 0.399902 48 4.87705 48 10.3999V38.3999C48 43.9228 43.5228 48.3999 38 48.3999H10C4.47715 48.3999 0 43.9227 0 38.3999V10.3999Z" fill="#0b9444"/>
    <path d="M20.3803 21.2533C21.0763 22.7029 22.0251 24.0615 23.2266 25.2631C24.4282 26.4647 25.7869 27.4135 27.2365 28.1095C27.3612 28.1693 27.4235 28.1993 27.5024 28.2223C27.7828 28.304 28.127 28.2453 28.3644 28.0753C28.4313 28.0274 28.4884 27.9703 28.6027 27.856C28.9523 27.5064 29.1271 27.3315 29.3029 27.2173C29.9658 26.7863 30.8204 26.7863 31.4833 27.2173C31.6591 27.3315 31.8339 27.5064 32.1835 27.856L32.3783 28.0508C32.9098 28.5823 33.1755 28.848 33.3198 29.1334C33.6069 29.7009 33.6069 30.3712 33.3198 30.9388C33.1755 31.2241 32.9098 31.4899 32.3783 32.0213L32.2207 32.1789C31.6911 32.7086 31.4263 32.9734 31.0662 33.1756C30.6667 33.4 30.0462 33.5614 29.588 33.56C29.1751 33.5588 28.8928 33.4787 28.3284 33.3185C25.295 32.4575 22.4326 30.8331 20.0447 28.4451C17.6567 26.0571 16.0322 23.1947 15.1712 20.1613C15.011 19.5969 14.9309 19.3147 14.9297 18.9017C14.9283 18.4435 15.0897 17.823 15.3141 17.4235C15.5164 17.0635 15.7812 16.7987 16.3108 16.269L16.4684 16.1114C16.9999 15.58 17.2656 15.3142 17.551 15.1699C18.1185 14.8828 18.7888 14.8828 19.3564 15.1699C19.6417 15.3142 19.9075 15.58 20.4389 16.1114L20.6338 16.3063C20.9834 16.6559 21.1582 16.8307 21.2725 17.0065C21.7035 17.6693 21.7035 18.5239 21.2725 19.1868C21.1582 19.3626 20.9834 19.5374 20.6338 19.887C20.5195 20.0013 20.4623 20.0585 20.4145 20.1253C20.2445 20.3627 20.1858 20.707 20.2675 20.9873C20.2905 21.0662 20.3204 21.1286 20.3803 21.2533Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>    
      `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} className="mb-8"/>;
  };
  
  export default PhoneIcon;
  




